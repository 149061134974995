<template>
  <div class="place-items-center p-search-input">
    <div :class="['p-search-input__wrapper', `p-search-input--${size}`]">
      <p-icon v-bind="prependIconAttributes" />
      <input
        ref="rootRef"
        v-model="search"
        v-bind="inputAttributes"
        :autofocus
        :placeholder="placeholder"
        @focus="handleFocus"
        @blur="handleBlur"
        @keyup="onDebounce"
      />
      <p-icon v-if="search" v-bind="appendIconAttributes" @click.stop="clearInput" />
    </div>
    <span
      v-if="showClose || focused || autofocus"
      class="p-search-input--cancel"
      @click="closeSearch"
      v-text="$t('general.cancel')"
    />
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['on-blur', 'on-close', 'on-focus', 'on-debounce', 'on-clear'])
const rootRef = ref<any>(null)

const props = defineProps({
  name: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  showClose: {
    type: Boolean,
    default: false,
  },
  debounceDelay: {
    type: Number,
    default: 500,
  },
  debounceValue: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
})

const { debounceDelay } = toRefs(props)

const onDebounce = $_.debounce(() => emit('on-debounce'), debounceDelay.value)

const search = defineModel<string | number>('modelValue')

const focused = ref<boolean>(props.showClose)

const appendIconAttributes = computed(
  (): { color: string; class: string; size: string; icon: string } => {
    return {
      color: 'fg-tertiary',
      class: 'p-search-input--close',
      size: 'xmd',
      icon: 'circle-close',
    }
  },
)

const prependIconAttributes = computed((): { color: string; icon: string; size: string } => {
  return {
    color: 'fg-secondary',
    icon: 'search',
    size: 'xmd',
  }
})

const inputAttributes = computed(() => {
  return {
    id: props.name,
    placeholder: props.placeholder,
    class: ['p-search-input__field'],
  }
})

const handleFocus = () => {
  focused.value = true
  emit('on-focus')
}

const handleBlur = () => {
  emit('on-blur')
}

const clearInput = () => {
  search.value = ''
  props.debounceValue && onDebounce()
  rootRef.value.focus()
  emit('on-clear')
}

const closeSearch = () => {
  search.value = ''
  focused.value = false
  emit('on-close')
}
</script>
